<template>
  <v-card
    tile
    class="card"
    :class="{ 'mt-2': !dense, 'mb-2': !dense }"
    color="rgb(255, 255, 255, 1)"
    ripple
    :elevation="16"
    height="100%"
  >
    <v-toolbar color="secondary" dark dense class="flex-wrap">
      <v-toolbar-title class="mr-2">
        {{ title | uppercase }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <global_refresh_button
        @click="$emit('update')"
        v-if="!not_fetched"
        :loading="loading"
        :time="time"
      />
    </v-toolbar>
    <v-expand-transition>
      <v-card-text v-show="!loading">
        <v-row dense>
          <v-col
            :cols="size.cols"
            :md="size.md"
            :sm="size.sm"
            :lg="size.lg"
            :xl="size.xl"
            v-for="(stat, index) in cards"
            :key="index"
          >
            <stat_card
              :icon="stat.icon"
              :count="stat.count"
              :name="stat.name"
              :money="stat.money || false"
            ></stat_card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: "profile_stat_card",
  components: {
    global_refresh_button: () => import("@/components/global_refresh_button"),
    stat_card: () => import("@/components/staticCard")
  },
  computed: {
    size() {
      let md, sm, lg, xl;
      if (this.cards.length % 4 === 0) {
        md = 4;
        sm = 6;
        xl = 2;
        lg = 3;
      } else if (this.cards.length % 3 === 0) {
        md = 5;
        sm = 6;
        xl = 3;
        lg = 4;
      } else if (this.cards.length % 2 === 0) {
        md = 12;
        sm = 12;
        xl = 6;
        lg = 6;
      } else {
        md = 4;
        sm = 6;
        xl = 2;
        lg = 3;
      }
      return {
        cols: 12,
        md,
        sm,
        lg,
        xl
      };
    }
  },
  props: {
    cards: {
      type: Array,
      default: () => []
    },
    time: {
      type: [String, Number, Boolean],
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    not_fetched: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped></style>
